

@font-face {
    font-family: lemonmilk;
    src: url('../Font/LEMONMILK-Regular.otf');
}

@font-face {
    font-family: lemonmilk;
    src: url('../Font/LEMONMILK-Bold.otf');
    font-weight: bold;
}

@font-face {
    font-family: roboto;
    src: url('../Font/Roboto-Light.ttf');
}

@font-face {
    font-family: roboto;
    src: url('../Font/Roboto-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: adam;
    src: url('../Font/LGCL.ttf');
}

@font-face {
    font-family: adam;
    src: url('../Font/LGCB.ttf');
    font-weight: bold;
}