:root {
      --mdc-theme-primary: #fff;
      --mdc-theme-secondary: blue;
}

html, body, #root {
    height: 100%;
    background-image: url('../img/bg.png');
    background-size: cover;
    margin: 0px;
    font-family: 'adam' !important;
  }

  option{
      color: #000 !important;
  }

  .top-bar-logo{
      height: 25px;
      display:block;
  }

  .summary{
      width: 600px;
      margin: auto
  }

  .no-pad{
      padding: 0 !important;
  }
/***new v3.2 code *I**/
.navbar-light{
    background-color: transparent;
}
.navbar-light .navbar-text, .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1);

    display: block;
    padding: 0 5px; 
}
.navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1);
    display: block;
    padding: 0;
    line-height: 40px;
    height: 40px;
}
.navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
    background: #67cbe2;
    color: #fff;
    font-weight: bold;
}
.btn-secondary {
    color: #fff;
    background-color: #24cced;
    border-color: transparent;
}
.dcc-dropdown{
    background: transparent;
    color: inherit;
    border-color: #eeeeee;
    font-weight: bold;
    font-size: 15px;
    height: 50px;
    padding: 0 3px;
    width: 80px;
    border-radius: 0;
}

.cred-box .input-group-append{
    margin-left: 0px;
}

.mobile-number-input,.mobile-number-input:focus{
    background: transparent !important;
    border-color: #eeeeee;
    color: inherit;
    height: 50px;
    font-family: 'lemonmilk' !important;
    text-align: center;
    border-radius: 0px;
}

.mobile-number-input::placeholder{
    color: inherit;
    opacity: 0.5;
}

.btn-secondary:not(:disabled):not(.disabled):active,.btn-secondary:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: #24cced;
    border-color: #24cced;
}

hr {
    border: 0.8px solid #ccc;
}

.cred-box{
    width: 100%;
    margin: auto;
}

.cred-box .dropdown-item.disabled,#login .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background: #ccc;
    pointer-events: none;
    /* background-color: transparent; */
}

.cred-box .dropdown-item, #login .dropdown-item, .login-mod .dropdown-item{
    font-weight: bold;
}

.cred-box .dropdown-menu.show, #login .dropdown-menu.show, .login-mod .dropdown-menu.show{
    display: block;
    max-height: 300px;
    overflow: scroll;
}

.cred-box .input-group-append.show{
    display:flex
}

.cred-box .input-group{
    display: inline-flex
}


.my-items .card-body div{
    height:50px;
    text-align: center;    
    margin: 2px 0;
}

.my-items .half{
    width: calc((100% / 2) - 3px);
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
}

.my-items .half img{
    width: 20px;
    margin-right: 10px;
}

.my-items .half  span{
    background-color: #24cced;
    color: #fff;
    padding: 3px 5px;
    margin-left: 5px;
    line-height: 11px;
}



.card-body {
    flex: 1 1 auto;
    padding: 0;
}

.no-bdr{
    border: none;
    border-radius: 0;
}

.sub-header{
    font-size: 17px;
    margin: 15px auto;
    width: 100%;
    text-align: center;
    color: #d81414;
    font-family: 'lemonmilk' !important;
}

.summary-blk .summary-blk-inner{
    width:90%;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.summary-blk .summary-blk-pkgs{
    width:90%;
    min-height: 40px;
    font-weight: bold;
}
.summary-blk .summary-blk-pkgs .summary-blk-pkgs-title{
    width: 100%;
    font-size: 18px;
    line-height: 40px;
}

.summary-blk .summary-blk-pkgs .summary-blk-pkgs-title .rwd-off{
    color:#a24234;
}

.loyalty .used-pkgs{
    margin: 20px auto 1px auto;;
}

.loyalty .used-pkgs ul{
    list-style: none;
    padding: 0;
}

.loyalty .used-pkgs ul li span{
    color: #24cced;
}


.loyalty .used-pkgs ul li button{
    border: none;
    margin-right: 5px;
    border-radius:2px;
    background: #a24234;
    color: #fff;
}
.summary-blk .summary-blk-inner .summary-blk-inner-title{
    width: calc(100% - 110px);
    font-size: 18px;
}

.summary-blk .summary-blk-inner .summary-blk-inner-title small{
    color: #24cced;
    font-weight: bold;
}

.summary-blk .summary-blk-inner .summary-blk-inner-subtotal{
    width: 110px;
    text-align: right;
    font-size: 18px;
}

.summary-blk .summary-blk-inner .input-group{
    width:110px;
    
}

.summary-blk .summary-blk-inner .input-group input{
    height: 40px;
    text-align: right;
    font-weight: bold;
    border: #e4e4e3;
    background: #e4e4e3;
}

.summary-blk .summary-blk-inner .input-group .input-group-text{
    border: #e4e4e3;
    background: #e4e4e3;
    font-weight: bold;
}

.wallet-blk {
    flex-direction: row;
}

.wallet-blk .card-body{
    width: 90%;
    margin: auto;
    flex: initial;
    display: block;
}

.wallet-blk .half div{
    width:100%
}
.wallet-blk .half{
    height:80px;
    text-align: left;    
    margin: 2px 0;
    width: calc((100% / 2) - 3px);
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 13px;
    font-weight: bold;
}

.wallet-blk .half .wlt-curr-date{
    font-size: 12px;
    color: #939298;
}

.wallet-blk .half  .wlt-curr-amt{
    color: #24cced;
}

.wallet-blk .half  .wlt-curr-offset{
    color: #a24234;
}

.wallet-blk .half .wlt-offset-option{
    font-size: 10px;
    color: #a24234;
}

.wallet-blk .half .wlt-offset-option .switch{
    width:100% !important;
}

.wallet-blk .half .wlt-offset-option span{
    display: inline-block;
    width: calc(100% - 65px)
}
.wallet-blk .half .wlt-offset-option span:first-child{
    width:65px;
}

.wallet-blk .vl{
    padding-left: 10px
}



.wallet-blk .half .label{
    display: inline-block;
    width: 65px;
    line-height: 15px;
    font-size: 15px;
}

.wallet-blk .half .content{
    display: inline-block;
    width: calc(100% - 65px);
    font-size: 20px;
    padding-right: 10px;
    text-align: right;
}

.tnx-total{
    background: #fdfdfd;
}

.tnx-receipt input{
    overflow: visible;
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
}



.tnx-total .tnx-total-inner{
    width: 50%;
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    margin: 30px auto;
}

.tnx-total .card-body{
    width:90%;
    margin: auto
}

.tnx-total button{
    display: block;
    background: #24cced;
    border-radius: 100vw;
    border:none;
    color: #fff;
    width:200px;
    font-weight: bold;
    font-size: 18px;
    height: 40px;
    margin: 30px auto;
}

.close-modal{
    border: none;
    background: transparent;
    float: right;
    color:#000;
    margin-bottom: 20px;
}

input:focus,button:focus{
    box-shadow: none !important;
}

.no-itm{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.tool-bar-nl button{
    background: transparent;
    color: #24cced;
    border: none;
}

.tool-bar-nl button:focus{
    background: transparent;
    color: #24cced;
    border: none;
}

.points-pill, .stamps-pill{
    
    width: 150px;
    margin: auto;
    text-align: center;
}

.points-pill h5,.stamps-pill h5{
    background: #24cced;
    color: #fff;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
}

.points-pill small, .stamps-pill small{
    font-weight: bold;
}



/*******/
.rmwc-icon {
    color:#000;
}

  .relative{
      position: relative;
  }
.show{
    display: block;
}

.show-flex{
    display: flex;
    justify-content: center;
}
.hide{
    display: none;
}
.container{
    height: 100%;
    padding : 0 !important;
    position: relative;
    border-top: 1px solid transparent;
    background: rgba(248,248,248,0.8)
}

.vl {
    border-left: 3px solid #e2e2e2;
    height: auto;
    display:inline-block;
}

.tnx-block .logo{
    display: block;
    height: 60px;
    margin: 30px auto;
    object-fit: contain;
    margin: 30px auto;
}

.tnx-block .header{
    font-family: 'lemonmilk' !important;
    text-align: center;
    font-size: 15px;
    text-decoration: underline;
    margin-bottom: 20px;
} 

.tnx-block .earn-box{
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 50px;
}

.tnx-block .earn-box .inner{
    width: 50%;
}

.tnx-block .earn-box .inner h4{
    font-family: 'lemonmilk';
    text-align: center;
    font-size: 15px;
    margin-bottom: 0px;
}

.tnx-block .earn-box .inner div{
    background: rgb(248 248 248 / 30%);;
    width: 70%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'lemonmilk';
}

.tnx-block .nxt-action{
    display: flex;
}

.tnx-block .nxt-action .inner{
    width: calc(100% - 2px);
}

.tnx-block .nxt-action .inner.l{
    border-right: 2px solid #fff;
}
.tnx-block .nxt-action .inner.r{
    border-left: 2px solid #fff;
}

.tnx-block .nxt-action .inner div{

}

.tnx-block .nxt-action .inner div img{
    height:55px;
    display: block;
    margin: 20px auto 10px auto;
}

.tnx-block .nxt-action .inner div h6{
    text-align: center;
    font-family: 'lemonmilk';
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 5px;
} 

.tnx-block .nxt-action .inner div h5{
    text-align: center;
    font-family: 'lemonmilk';
    font-size: 20px;
    margin-bottom: 5px;
} 

.tnx-block .nxt-action .inner div p{
    text-align: center;
    font-family: 'lemonmilk';
    font-size: 11px;
    margin-bottom: 5px;
} 

.tnx-block .nxt-action .inner div button{
    border: none;
    background: #01b8f2;
    color: #fff;
    font-family: 'lemonmilk';
    font-size: 12px;
    display: block;
    width: 130px;
    margin: auto;
    border-radius:10px;
    height: 40px;
}

.mdc-toolbar {
    background-color: #f4f4f4;
}

.mdc-toolbar__title,.mdc-drawer .mdc-drawer__title,.mdc-drawer .mdc-drawer__subtitle,.mdc-drawer .mdc-list-item{
    font-family: 'lemonmilk' !important;
}

.mdc-drawer .mdc-list-item{
    font-size: 12px !important
}

.mdc-toolbar__menu-icon + .mdc-toolbar__title {
    margin-left: auto;
    margin-right: auto;
}

.mdc-snackbar {
    z-index: 9999;
}
.mdc-snackbar__label{
    font-family: 'lemonmilk' !important;
    font-size: 9px;
}

.mdc-snackbar__actions .mdc-button__label{
    color: #fff !important;
}

.mdc-dialog .mdc-dialog__content {
    font-family: 'lemonmilk' !important;
    font-size: 9px;
}

.bg{
    background-image: url("../img/bg.png");
}

#landing .inner,#login .inner{
    width:600px;
    max-width: 100%;
    margin: auto;
    height:100%;
}

#landing .inner .top,#login .inner .top{
    display: flex;
    height: 80%;
    align-items: center;

}

.central-logo{
    width: 150px;
    margin: 50px auto;
    display: block;
}

.mdc-snackbar__surface{
    background-color: #fff;
}

.mdc-snackbar__surface .rmwc-icon{
    color:#000 !important;
} 
.mdc-snackbar__label {
    color: rgba(0,0,0,.87);
}


.mdc-snackbar__actions .mdc-button__label {
    color: #000 !important;
}



#landing .inner .btm,#login .inner .btm{
    display: flex;
    height: 20%;
    align-items: center;

}

.landing-logo{
    width:250px;
    display: block;
    margin: auto;
}


/*buttons*/

.tb-big-btn{
    border-radius: 50vw;
    width: 80%;
    max-width: 500px;
    display: block;
    margin: auto;
    font-family: 'lemonmilk';
    font-size: 20px;
    height: 40px;
}

.tb-duo{
    border-radius: 50vw;
    width: 45%;
    max-width: 300px;
    margin: 5px;
    font-family: 'lemonmilk';
    font-size: 15px;
    height: 45px;
}

.btn-blue{
    color: #fff;
    background-color: #272262 !important;
}

.ldTxt{
    color: #272262;
    font-size: 11px;
}

.blue{
    color: #272262 !important;
}

.btn-white{
    color: #000 !important;
    background-color: #fff !important;
}

.btn-transparent{
    color: #fff !important;
    background-color: transparent !important;
}

/* form */
.text-field, .select, select:focus{
    background: transparent;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
}


.form-group label{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
}

.spinner-border {
    display: block;
    margin: auto;
}


 .tc.mdc-form-field {
    font-size: 8px
 }

 .mdc-floating-label, .mdc-form-field {
    color: #fff !important;
    font-family: 'lemonmilk' !important;
 }

 .mdc-floating-label--float-above{
   color:  #fff !important;
 }
 .mdc-floating-label--shake{
   color:  #fff !important;
 }

 .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: #fff !important;
}
.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: #fff !important;
}

.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: #fff !important;
}

 .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: #fff !important;
    background-color: transparent;
}

 .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter, .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: #fff !important;
}

.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input, .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover,.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: #fff !important; 
    color: #fff !important;
    font-size: 20px;
}

.mdc-text-field .mdc-floating-label {
    top: 9px !important;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.rmwc-circular-progress{
    color: #fff !important;
    margin: auto;
    display: block;
}

.frm-box{
    width:80%;
    margin: auto;
}

.dashboard {
    width:100%;
    max-width:600px;
    margin: auto;
    padding-bottom:5px;
}

.dashboard .loyalty .tbl-welcome button{
    color:#a24234;
    background: transparent;
    border: none;
}

.swal2-title{
    font-size: 15px !important;
}

.swal2-icon{
    font-size: 10px;
}


.dashboard .card{
    width:100%;
    margin:auto;
    margin-bottom: 20px;
    background-color: transparent;
}

.dashboard .spt-box{
    width: 70%;
    max-width: 230px;
    height: 60px;
    border-radius: 20px;
    border-radius: 10px;
    background: #fff;
    border : 2px solid #5ec5e8;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.dashboard .spt-box span{
    font-size: 25px;
    font-family: 'lemonmilk' !important;
}

.dashboard h5{
    font-size: 15px;
    font-family: 'lemonmilk' !important;
    text-align: center;
    margin-bottom: 40px
}

.dashboard .bx-action{
    text-align: center;
}

.dashboard .bx-action button{
    width: 48%;
    max-width: 168px;
    background-color: #01b8f2;
    background-size: 35px;
    background-position: 5px 50%;
    min-height: 60px;
    padding-left: 50px;
    margin-top: 30px;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: none !important
}

.dashboard .bx-action button div.outer{
    border-left: 1px solid #fff;
}

.dashboard .bx-action button div{
    color: #FFF;
    font-size: 9px;
    text-align: center;
    font-weight: bold;
    line-height: 9px;
}

.dashboard .bx-action button div p{
    color: #FFF;
    font-size: 15px;
    margin: 10px auto 1px auto;
    font-weight: bold;
}


.dashboard .bx-action button.tb{
    background-image: url('../img/tb.png');
    margin-right: 20px;
}

.dashboard .bx-action button.fb{
    background-image: url('../img/fb.png')
}

.dashboard .tc-chk{
    font-size: 10px;
    font-weight: bold;
    margin: 10px auto;
    width: 90%;
    text-align: center;
}

.dashboard .tc-chk a{
    color: #01b8f2;
} 

.dashboard .form-check-input {
    margin-top: 0.001rem;
}

.ftr-msg{
    width: 90%;
    font-size: 10px;
    color: inherit;
    margin:10px auto 40px auto;
}

.ftr{
    position: fixed;
    bottom: 0px;
    height: 30px;
    width: 100%;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ftr img{
    width:75%;
    display: block;
    margin: auto;
    max-width: 169px;
}

.tnx-block .cloak{
    position: absolute;
    z-index: 9;
    top:0;
    width: 100%;
    height: 100%;
    align-items: center;
    background: rgba(255,255,255, 1)
}

.tnx-block .cloak div{
    margin-top: 3vh;
}

.tnx-block .log-mod{
    height: 67vh;
    overflow: auto;
}

.tnx-block .log-mod .inner{
    width:85%;
    margin: auto;
}

.tnx-block .log-mod .ben-con{
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
}

.tnx-block .log-mod .benefits{
    display: flex
}

.tnx-block .log-mod .benefits .inner{
    width : 33.33%;
}

.tnx-block .log-mod .benefits .inner img{
    width: 60%;
    display: block;
    margin: auto;
}

.tnx-block .log-mod p{
    text-decoration: underline;
    font-family: 'lemonmilk';
    font-size: 12px;
    text-align: center;
    margin: 20px auto;
}

.tnx-block .log-mod .tc-chk{
    font-size: 10px;
    font-family: 'lemonmilk';
    font-weight: normal;
    margin: 20px auto;
    width: 90%;
    text-align: center;
}

.tnx-block .log-mod h4{
    font-family: 'lemonmilk';
    font-size: 15px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    padding-left: 10px;
}

.login-modal .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input,  .login-modal .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover,  .login-modal .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: #272262 !important; 
    color:#272262 !important;
    font-size: 20px;
}

.login-modal .rmwc-circular-progress {
    color: #272262 !important;
    margin: auto;
    display: block;
}

#login .input-group, .login-mod .input-group {
    border-bottom: 1px solid #000;
}

#login .input-group input, .login-mod .input-group input{
    background: transparent;
    text-align: left; 
    border: none;
}

#login .input-group button, .login-mod .input-group button{
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
    font-size: 15px;
    padding: 0 3px;
    color:#000;
}

.login-mod {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-mod h4 {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.login-mod .otp-box{
    background: #f1f1f1;
    padding:5px 10px;
    margin-bottom: 40px;
}

.btn-black{
    background: #000;
    color:#fff;
}

.otp-form-container .otp-inputs-section .otp-input-group input {
    height: 50px !important;
}

.login-mod .otp-box .otp-input{
    height: 50px;
    margin: 5px;
    width: 40px !important;
    color: #000;
}

.login-modal {
    font-family: 'lemonmilk' !important;
}

.login-modal .mdc-dialog__title{
    font-size: 12px;
    text-align: center;
}

.login-modal .mdc-button:not(:disabled){
    color:#fff;
    font-family: 'lemonmilk' !important;
}

.login-modal  .mdc-floating-label, .login-modal  .mdc-form-field {
    color: #272262 !important;
    font-family: 'lemonmilk' !important;
 }

 .login-modal  .mdc-floating-label--float-above{
   color: #272262 !important;
 }
 .login-modal  .mdc-floating-label--shake{
   color:  #272262 !important;
 }



.modal .options {
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal .options h4{
    text-align: center;
    font-weight: bold;
    font-family: 'adam';
}

.modal .options button{
    text-align: center;
    font-weight: bold;
    font-family: 'adam';
}

.modal .options button i{
    padding-top: 5px
}

.modal p{
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}


.btn-black{
    background: #000;
    color:#fff;
}

 .login-modal .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input,  .login-modal .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover,  .login-modal .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: #272262 !important; 
    color:#272262 !important;
    font-size: 20px;
}

.login-modal .rmwc-circular-progress {
    color: #272262 !important;
    margin: auto;
    display: block;
}


/** typography **/
.text-white{
    color: #fff;
}
.centered{
    text-align: center;
}

.mb-30{
    margin-bottom:30px !important;
}

small{
    font-size: 12px;
}

.left{
    float: left;
}

.right{
    float: right;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }.no-margin{
      margin :0 !important;
  }



.popover-header {
    font-size: 12px;
    text-align: center;
}

.topups button{
    font-weight: bold;
}

.topup-menu{
    display: flex;
    justify-content: center;
    align-items: center;
}

.topup-menu .btn-group{
    margin: 10px auto !important;
}

.topup-menu .btn-group .btn{
    margin: 0px 1px !important;
    min-width: 97px;
    background: #e4e4e3;
    color: #868383;
}

.topup-menu .btn-secondary:not(:disabled):not(.disabled).active{
    background-color: #24cced;
    border: none !important;
}

.topup-options h4{
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    color: #24cced;
    width:100%;
}

.topup-options .wlt-tup-amt .input-group{
    width: 125px;
    margin: auto;
}

.topup-options .wlt-tup-amt .input-group input{
    -webkit-appearance: none;
    text-align: right;
    color: #24cced;
}

.topup-options .wlt-tup-amt .input-group .input-group-text{
    color: #24cced;
}

.topup-options .wlt-tup-amt  .quick-amt button{
    -webkit-appearance: none;
    height: 40px;
    border: none;
    border-radius: 10px;
    width: calc((100% / 4) - 10px);
    background: #e4e4e3;
    color: #fff;
    background-repeat: no-repeat;
    margin: 0 10px;
    background-size: 20px;
    background-position: 50%;
}

.topup-options .wlt-tup-amt  .quick-amt button.active{
    background-color: #24cced;
    border: none !important;
}

.topup-options select{
    -webkit-appearance: none !important;
    text-align-last:center;
}

.topup-options button{
    text-align: center;
    padding: 0;
}

.topup-options .details select{
    -webkit-appearance: none;
    display: block;
    width: 70%;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: #24cced;
    background-color: #e4e4e3;
    background-clip: padding-box;
    border: 1px solid #e4e4e3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.topup-options .details input{
    -webkit-appearance: none;
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 70%;
    line-height: 1.5;
    color: #24cced;
    height: calc(1.5em + .75rem + 2px);
    margin: auto;
    background: #e4e4e3;
    border: 1px solid #e4e4e3;

}

.topup-options .action-fld{
    text-align: center;
    justify-content: center;
    border-top: 1px solid transparent
}
.topup-options .action-fld button{
    -webkit-appearance: none;
    width: 40%;
    height: 40px;
    border-radius: 50vw;
    border: none;
    margin: 10px;
}

.topup-options .action-fld .cancel{
    color: #24cced;
    border: 2px solid #24cced;
    background: #fff;
}

.topup-options .action-fld .confirm{
    color: #fff;
    border: 2px solid #24cced;
    background: #24cced;
}

.topup-options .add-more button{
    -webkit-appearance: none;
    height: 45px;
    width: 45px;
    border-radius: 50vw;
    color: #24cced;
    border: 3px solid #24cced;
    background: #fff;
    display: block;
    margin: auto;
}

.topup-options .multi-fld-blk .input-group{
    -webkit-appearance: none;
    width:80%;
    background: #e4e4e3;
    border: 1px solid #e4e4e3;
    
    border-radius: 5px;
}

.topup-options .multi-fld-blk .input-group input{
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid #e4e4e3;
    font-weight: bold;
    text-align: center;
    width: 50px;
}

.topup-options .multi-fld-blk .input-group select{
    -webkit-appearance: none;
    background: #e4e4e3;
    width: calc(100% - 90px);
    font-weight: bold;
    border: 1px solid #e4e4e3;
}

.topup-options .multi-fld-blk .input-group button{
    -webkit-appearance: none;
    background: #f00;
    border: 1px solid #f00;
    color:#fff;
    width: 40px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pwd-box input{
    text-align: center;
    margin: 20px auto;
}


  @media only screen and (max-width: 600px) {
    .dashboard .loyalty .stamps .stamp{
        width:72px;
        height:72px;
    }

    .summary{
        width: 95%;
    }

    .dashboard .loyalty .stamps .stamp span span{
        width: 72px;
        height: 72px;
        display: block;
        text-align: center;
        line-height: 72px;
        font-size: 25px;
    }
  }



@keyframes ripple {
    0%, 35% {
      transform: scale(0);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.8;
    }
    100% {
      opacity: 0;
      transform: scale(4);
    }
  }